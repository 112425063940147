var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-navigation-drawer',{style:({ top: _vm.$vuetify.breakpoint.smAndDown ? '56px' : '64px' }),attrs:{"mini-variant":_vm.$vuetify.breakpoint.smAndDown,"width":"280","clipped":"","permanent":"","left":"","fixed":"","touchless":"","expand-on-hover":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-list-item',[_c('v-list-item-action',[_c('v-icon',{staticClass:"blue--text accent-4"},[_vm._v(" school ")])],1),_c('v-list-item-content',[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" Tutorials ")])])],1)]},proxy:true}])},[_c('v-divider'),_c('v-list',_vm._l((_vm.routes),function(item,index){return _c('v-list-group',{key:index,attrs:{"value":true,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.sentenceCasing(item.name.replace(/-/g, ' '))))])],1)]},proxy:true}],null,true)},_vm._l((item.children),function(child,i){return _c('v-list-item',{key:i,attrs:{"link":"","to":{
            name: 'tutorial',
            params: { id: child.file },
            hash: child.name
          }}},[_c('v-list-item-title',{staticClass:"links"},[_vm._v(_vm._s(_vm.sentenceCasing(child.name.substring(1).replace(/-/g, ' '))))])],1)}),1)}),1)],1),_c('div',{class:{
      'sm-and-down-left-padding': _vm.$vuetify.breakpoint.smAndDown,
      'sm-and-up-left-padding': _vm.$vuetify.breakpoint.smAndUp
    },staticStyle:{"min-height":"100%"}},[(_vm.$route.params.id)?_c('div',{staticClass:"md",domProps:{"innerHTML":_vm._s(_vm.mdParser(require(("./Markdown/" + (_vm.$route.params.id) + ".md"))))}}):_vm._e(),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }